import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { interval, Observable } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {
  private currentVersion: string;
  private versionFile = '/assets/version.json';

  constructor(private http: HttpClient) {}

  initVersionCheck(url: string = this.versionFile, frequency: number = 10000): void {
    this.http.get<{ version: string }>(url)
      .pipe(first())
      .subscribe((versionInfo) => {
        this.currentVersion = versionInfo.version;
      });

    interval(frequency).subscribe(() => {
      this.checkVersion(url);
    });
  }

  checkVersion(url: string): void {
    this.http.get<{ version: string }>(url)
      .subscribe((versionInfo) => {
        const latestVersion = versionInfo.version;
        if (this.currentVersion && this.currentVersion !== latestVersion) {
          this.clearCacheAndReload();
        }
      });
  }

  private clearCacheAndReload(): void {
    if (confirm("Nueva versión disponible. ¿Quieres actualizar?")) {
      localStorage.clear();
      caches.keys().then(names => {
        names.forEach(name => caches.delete(name));
      });
      window.location.reload();
    }
  }

  getVersion(): Observable<{ version: string }> {
    return this.http.get<{ version: string }>(this.versionFile);
  }
}