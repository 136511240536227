import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { VexRoutes } from 'src/@vex/interfaces/vex-route.interface';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';
import { AuthGuard } from './auth/auth.guard';
import { IndexComponent } from 'src/app/pages/archivo/pages/index/index.component';
const routes: VexRoutes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'detalle/:id',
    loadChildren: () => import('./pages/detalle/detalle.module').then(m => m.DetalleModule),
  },
  {
    path: 'archivo/:id',
    loadChildren: () => import('./pages/archivo/archivo.module').then(m => m.ArchivoModule),
  },
  {
    path: '',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'dashboards/analytics',
        redirectTo: '/'
      },
      {
        path: '',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
        canActivate: [AuthGuard],
        data: { modules: ['Dashboard'] }
      },
      {
        path: 'productos',
        loadChildren: () => import('./pages/producto/producto.module').then(m => m.ProductoModule),
        canActivate: [AuthGuard],
        data: { modules: ['Productos'] }
      },
      {
        path: 'venta-nuevos',
        loadChildren: () => import('./pages/venta-nuevos/venta-nuevos.module').then(m => m.VentaNuevosModule),
        canActivate: [AuthGuard],
        data: { modules: ['Venta nuevos'] }
      },
      {
        path: 'dashboard-notify',
        loadChildren: () => import('./pages/dashboard-notify/dashboard-notify.module').then(m => m.DashboardNotifyModule),
        canActivate: [AuthGuard],
        data: { modules: ['dashboard-notify'] }
      },
      {
        path: 'pdf-files',
        loadChildren: () => import('./pages/pdf-files/pdf-files.module').then(m => m.PdfFilesModule),
        canActivate: [AuthGuard],
        data: { modules: ['pdf-files'] }
      },
      // {
      //   path: 'ventas-nuevos',
      //   loadChildren: () => import('./pages/producto/producto.module').then(m => m.ProductoModule),
      //   canActivate: [AuthGuard],
      //   data: { modules: ['Productos'] }
      // },
      {
        path: 'apps',
        children: [
          {
            path: 'sucursales',
            loadChildren: () => import('./pages/apps/sucursales/sucursales.module').then(m => m.SucursalesModule),
            canActivate: [AuthGuard],
            data: { modules: ['Sucursales'] }
          },
          {
            path: 'empleados',
            loadChildren: () => import('./pages/apps/empleados/empleados.module').then(m => m.EmpleadosModule),
            canActivate: [AuthGuard],
            data: { modules: ['Empleados'] }
          },
          {
            path: 'roles',
            loadChildren: () => import('./pages/apps/roles/roles.module').then(m => m.RolesModule),
            canActivate: [AuthGuard],
            data: { modules: ['Roles'] }
          },
          {
            path: 'clientes',
            loadChildren: () => import('./pages/apps/cliente/cliente.module').then(m => m.ClienteModule),
            canActivate: [AuthGuard],
            data: { modules: ['Clientes'] }
          },
          {
            path: 'categorias',
            loadChildren: () => import('./pages/apps/categorias/categorias.module').then(m => m.CategoriasModule),
            canActivate: [AuthGuard],
            data: { modules: ['Categorías'] }
          },
          {
            path: 'prestamos',
            loadChildren: () => import('./pages/apps/prestamos/prestamos.module').then(m => m.PrestamosModule),
            canActivate: [AuthGuard],
            data: { modules: ['Préstamos'] }
          },
          {
            path: 'documentos',
            loadChildren: () => import('./pages/apps/documento/documento.module').then(m => m.DocumentoModule),
            canActivate: [AuthGuard],
            data: { modules: ['Documentos'] }
          },
          {
            path: 'tiposprestamos',
            loadChildren: () => import('./pages/apps/tipos/tiposcreditos.module').then(m => m.TiposcreditosModule),
            canActivate: [AuthGuard],
            data: { modules: ['Tipos P.'] }
          },
          {
            path: 'pagos',
            loadChildren: () => import('./pages/apps/pagos/pagos.module').then(m => m.PagosModule),
            canActivate: [AuthGuard],
            data: { modules: ['Pagos'] }
          },
          {
            path: 'almacenes',
            loadChildren: () => import('./pages/apps/almacenes/almacenes.module').then(m => m.AlmacenesModule),
            canActivate: [AuthGuard],
            data: { modules: ['Almacenes'] }
          },
          {
            path: 'articulos',
            loadChildren: () => import('./pages/apps/articulos/articulos.module').then(m => m.ArticulosModule),
            canActivate: [AuthGuard],
            data: { modules: ['Artículos'] }
          },
          {
            path: 'trasladosp/:id',
            loadChildren: () => import('./pages/apps/pendientes/pendientes.module').then(m => m.PendientesModule),
            canActivate: [AuthGuard],
            data: { modules: ['Traslados (P)'] }
          },
          {
            path: 'limbo',
            loadChildren: () => import('./pages/apps/llamadas/llamadas.module').then(m => m.LlamadasModule),
            canActivate: [AuthGuard],
            data: { modules: ['Limbo'] }
          },
          {
            path: 'registros',
            loadChildren: () => import('./pages/apps/registros/registros.module').then(m => m.RegistrosModule),
            canActivate: [AuthGuard],
            data: { modules: ['Registros Diarios'] }
          },
          {
            path: 'ventas',
            loadChildren: () => import('./pages/apps/ventas/ventas.module').then(m => m.VentasModule),
            canActivate: [AuthGuard],
            data: { modules: ['Ventas'] }
          },
        ]
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/login'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    relativeLinkResolution: 'corrected',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule, QuicklinkModule]
})
export class AppRoutingModule {
}
